$(document).on("turbolinks:load", function () {
    div_type_request()
    showFilter("#companies")
    show_request_action()
    // FilterStockRecordDates(false);
    // Llamar a la función con clearDate = true
    // $("#cert_company").change(function() {
    //     FilterStockRecordDates(true);
    // });
    $("#request_type").change(div_type_request)
    $("#request_type").change(div_company)
    $("#request_decision").change(show_request_action)
    $("#request_decision").change(function() {
        $(".motive_field").find("input[type!='hidden'], select, textarea").val("");
    });
});

function div_type_request() {
    // Clear both sets of fields initially
    if($("#request_type option:selected").val() === "1"){
        $("#succession_fields").show();
        $("#heritage_fields").hide();
        $("#sell_purchase_fields").hide()
        $("#request_subtype").hide()
        $("#certification_fields").hide();
    }
    else if($("#request_type option:selected").val() === "2"){
        $("#succession_fields").hide();
        $("#heritage_fields").show();
        $("#sell_purchase_fields").hide()
        $("#request_subtype").hide()
        $("#certification_fields").hide();
    }
    else if ($("#request_type option:selected").val() === "3"){
        $("#succession_fields").hide();
        $("#heritage_fields").hide();
        $("#sell_purchase_fields").show()
        $("#request_subtype").show()
        $("#request_bank_entity").hide()
        $("#certification_fields").hide();
    }
    else if ($("#request_type option:selected").val() === "4"){
        $("#succession_fields").hide();
        $("#heritage_fields").hide();
        $("#sell_purchase_fields").show()
        $("#request_subtype").show()
        $("#request_bank_entity").show()
        $("#certification_fields").hide();
    }
    else if (($("#request_type option:selected").val() === "5") || ($("#request_type option:selected").val() === "6")){
        $("#certification_fields").show();
        $("#succession_fields").hide();
        $("#heritage_fields").hide();
        $("#sell_purchase_fields").hide()
        $("#request_subtype").hide()
        $("#request_bank_entity").hide()
    }
    else {
        $("#succession_fields").hide();
        $("#heritage_fields").hide();
        $("#sell_purchase_fields").hide()
        $("#request_subtype").hide()
        $("#certification_fields").hide()
    }
}

function div_company () {
    if($("#request_type option:selected").val() === "4"){
        let stockholder_id = $("#request_stockholder_id").val()
        //LLENA LOS VALORES DEL SELECT A PARTIR DE LA RESPUESTA
        $("#companies").empty();
        $("#cert_company").empty();
        getFilterCompanies(stockholder_id).then((response) => {
            $("#companies").append("<option value=''>Seleccione...</option>");
            if (response){
                response.forEach(company => {
                    $("#companies").append(`<option value="${company.id}">${company.name + " - " + company.document}</option>`);
                });
            }
            //CAMBIA LOS VALORES DEL SELECT
            showFilter("#companies");
        });
    }
    else if($("#request_type option:selected").val() === "3"){
        //LLENA LOS VALORES DEL SELECT A PARTIR DE LA RESPUESTA
        $("#companies").empty();
        $("#cert_company").empty();

        getAllCompanies().then((response) => {
            $("#companies").append("<option value=''>Seleccione...</option>");
            if ( response ){
                response.forEach(company => {
                    $("#companies").append(`<option value="${company.id}">${company.name + " - " + company.document}</option>`);
                });
            }
            //CAMBIA LOS VALORES DEL SELECT
            showFilter("#companies");
        });
    }
    else if ($("#request_type option:selected").val() === "5" || $("#request_type option:selected").val() === "6"){
        let stockholder_id = $("#request_stockholder_id").val()
        //LLENA LOS VALORES DEL SELECT A PARTIR DE LA RESPUESTA
        $("#cert_company").empty();
        getFilterCompanies(stockholder_id).then((response) => {
            $("#cert_company").append("<option value=''>Seleccione...</option>");
            if (response){
                response.forEach(company => {
                    $("#cert_company").append(`<option value="${company.id}">${company.name + " - " + company.document}</option>`);
                });
            }
            //CAMBIA LOS VALORES DEL SELECT
            showFilter("#cert_company");
        });
    }
}

function show_request_action(){
    //LIMPIA EL CAMPO DE MOTIVO DE LA SOLICITUD CUANDO SE CAMBIA DE DECISIÓN
    $("#request_motive").val("")
    //AL HACER CHANGE VACIA LOS CAMPOS
    if($("#request_decision option:selected").val() === "Aprobar"){
        $("#modify_params").hide()
        $("#validate_request").hide()
        $("#approve_request").show()
        $("#update_request").hide()
        $("#refuse_request").hide()
        $("#request_motive").hide()
        $("#request_stocks").attr("disabled", true)
        $("#send_method").attr("disabled", true)
        //DEBE TOMAR EL VALUE INICIAL SI CAMBIO
        $("#request_stocks").val($("#request_stock_was").val())
        // $("#send_method").val($("#send_method_was").val())
    }
    else if($("#request_decision option:selected").val() === "Actualizar"){
        $("#modify_params").hide()
        $("#validate_request").hide()
        $("#approve_request").hide()
        $("#update_request").show()
        $("#refuse_request").hide()
        $("#request_motive").show()
        $("#request_stocks").attr("disabled", false)
    }
    else if($("#request_decision option:selected").val() === "Generar"){
        $("#modify_params").hide()
        $("#validate_request").show()
        $("#approve_request").hide()
        $("#update_request").hide()
        $("#refuse_request").hide()
        $("#request_motive").hide()
        $("#request_stocks").attr("disabled", true)
        $("#request_stocks").val($("#request_stock_was").val())
        $("#send_method").attr("disabled", false)
        $("#filter_date").attr("disabled", false)
    }
    else if($("#request_decision option:selected").val() === "Modificar Parámetros"){
        $("#modify_params").show()
        $("#validate_request").hide()
        $("#approve_request").hide()
        $("#update_request").hide()
        $("#refuse_request").hide()
        $("#request_motive").hide()
        $("#request_stocks").attr("disabled", true)
        $("#request_stocks").val($("#request_stock_was").val())
        $("#send_method").val($("#send_method_was").val())
    }
    else if ($("#request_decision option:selected").val() === "Rechazar"){
        $("#modify_params").hide()
        $("#validate_request").hide()
        $("#approve_request").hide()
        $("#update_request").hide()
        $("#refuse_request").show()
        $("#request_motive").show()
        $("#request_stocks").attr("disabled", true)
        $("#request_stocks").val($("#request_stock_was").val())
        $("#send_method").val($("#send_method_was").val())
        $("#filter_date").val($("#cert_date_was").val())
        $("#send_method").attr("disabled", true)
        $("#filter_date").attr("disabled", true)
    }
    else{
        $("#modify_params").hide()
        $("#validate_request").hide()
        $("#approve_request").hide()
        $("#update_request").hide()
        $("#refuse_request").hide()
        $("#request_motive").hide()
        $("#request_stocks").attr("disabled", true)
    }
}

function getFilterCompanies(stockholder_id) {
    return $.ajax({
        url: '/stockholders/filter_companies/' + stockholder_id,
        method: 'GET',
    });
}

function getAllCompanies() {
    return $.ajax({
        url: '/stockholders/all_companies/',
        method: 'GET',
    });
}

function FilterStockRecordDates(clearDate = true){
    if($("#request_type option:selected").val() === "5"){
        let stockholder_id = $("#request_stockholder_id").val()
        let company_id = $("#cert_company").val()

        getFilterStockRecord(stockholder_id, company_id).then((response) => {
            if (clearDate) {
                $("#filter_date").val(""); // Vaciar el campo de cert_date
            }
            if (response){
                //mostrar en datepicker las fechas de la respuesta
                $("#filter_date").datepicker('destroy');
                $("#filter_date").datepicker({
                    format: 'dd-mm-yyyy',
                    language: 'es',
                    beforeShowDay: function(date) {
                        let dia = date.getDate().toString().padStart(2, '0');
                        let mes = (date.getMonth() + 1).toString().padStart(2, '0');
                        let anio = date.getFullYear();
                        let fecha = dia + '-' + mes + '-' + anio;
                        return response.includes(fecha) ? true : false;
                    }
                });
            }
        });
    }
}

function getFilterStockRecord(stockholder_id, company_id){
    return $.ajax({
        url: '/stockholders/filter_stock_company_records' + "/" + stockholder_id + "/" + company_id,
        method: 'GET',
    })
}


function showFilter(id) {
    $(id).select2({
        placeholder: "Indique el nombre",
        theme: "bootstrap-5",
        allowClear: true,
        width: '100%',
        language: {
          noResults: function() {
            return "No hay resultados";
        }
        }
    })
}